/* eslint-disable @typescript-eslint/no-misused-promises */
import {
    AddNewBtn,
    CloseIconBtn,
    ItemCard,
    ItemWrap,
    NewSubmitTitle,
    NewSubmitWrap,
    SubmitNewItemContainer,
} from 'src/styles/newItemSubmit/newItemSubmit.styles.tsx';
import { Typography, Icon } from '@worthy-npm/worthy-common-ui-components';
import { Icon as CommonIcons } from '../Icons';
import { useCallback, useEffect, useState } from 'react';
import { worthyService } from 'src/services/api/worthy.service.ts';
import { useAppDispatch, useAppSelector } from 'src/store';
import { createTempInValidItem, getItems, removeTempInValidItem } from 'src/store/itemsSlice';
import { ItemTypes } from 'src/constants/item.constants.tsx';
import { getMainItemByValueCategory, redirectTo } from 'src/helpers/common.ts';
import { Item } from 'src/types/item.ts';
import { isItemPreScheduling } from 'src/helpers/scheduling/item.ts';
import { getSubmissionUrl } from 'src/config';

interface INewItemSubmit {
    allPending: boolean;
}

const NewItemSubmit = ({ allPending }: INewItemSubmit) => {
    const itemList = useAppSelector(getItems);
    const dispatch = useAppDispatch();

    const [ open, setOpen ] = useState(false);
    const [ selected, setSelected ] = useState<string>('');
    const [ mainItem, setMainItem ] = useState<Item>();
    const [ disabled, setDisabled ] = useState(true);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        const schedulingItems = itemList.filter((item) => isItemPreScheduling(item.status));
        const main = getMainItemByValueCategory(schedulingItems);
        setMainItem(main);
    }, []);

    const addNewItem = async () => {
        setDisabled(true);
        setLoading(true);
        if (mainItem) {
            try {
                dispatch(createTempInValidItem({ itemType: selected }));
                await worthyService.addAnotherItem(mainItem.publicId, selected);
            } catch (err) {
                dispatch(removeTempInValidItem());
                console.error(err);
            }
        }
        resetState();
    };

    const resetState = useCallback(() => {
        setOpen(false);
        setSelected('');
        setLoading(false);
        setDisabled(true);
    }, []);

    const onSelect = (alias: string) => {
        setSelected(alias);
        setDisabled(false);
    };

    return (
        <NewSubmitWrap isOpen={ open }>
            { !open && (
                <Typography
                    variant="button"
                    color="highlight.main"
                    fontSize="16px"
                    data-automation="submit-new-item"
                    sx={ { cursor: 'pointer' } }
                    onClick={ () => {
                        if (allPending) {
                            redirectTo({url: getSubmissionUrl()})
                        } else {
                            setOpen(true);
                        }
                    } }
                >
                    <CommonIcons.PlusIcon sx={ { width: '12px', height: '12px', marginRight: 0.5 } } />
                    Submit a new item
                </Typography>
            ) }
            { open && (
                <SubmitNewItemContainer>
                    <NewSubmitTitle>
                        <Typography
                            variant="body1"
                            color="text.primary"
                            fontWeight="700"
                            width="218px"
                            sx={ { paddingLeft: '4px' } }
                        >
                            What type of item do you have?
                        </Typography>
                        <CloseIconBtn
                            variant="default"
                            size="small"
                            color="secondary"
                            onClick={ () => !loading && resetState() }
                        >
                            <Icon.CloseIcon
                                data-automation="close"
                                style={ { width: '24px', height: '24px' } }
                            />
                        </CloseIconBtn>
                    </NewSubmitTitle>
                    <ItemWrap>
                        { ItemTypes.map((item, index) => (
                            <ItemCard
                                key={ index }
                                onClick={ () => !loading && onSelect(item.alias) }
                                selected={ selected === item.alias }
                            >
                                { item.icon }
                                <Typography
                                    variant="body1"
                                    color={ selected === item.alias ? 'text.secondary' : 'text.primary' }
                                >
                                    { item.type }
                                </Typography>
                            </ItemCard>
                        )) }
                    </ItemWrap>
                    <AddNewBtn
                        size="large"
                        variant="contained"
                        disabled={ disabled }
                        onClick={ addNewItem }
                    >
                        <Typography
                            variant="button"
                            fontSize={ '18px' }
                        >
                            Add item
                        </Typography>
                    </AddNewBtn>
                </SubmitNewItemContainer>

            ) }
        </NewSubmitWrap>
    );
};

export default NewItemSubmit;

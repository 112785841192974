import { backendService } from 'src/services/api/backend.service.ts';
import { createAppSlice } from 'src/store/createAppSlice.ts';
import { PayloadAction } from '@reduxjs/toolkit';
import { Item, SchedulingDetails } from 'src/types/item.ts';
import { DropOffLocations, SchedulePickupParams, worthyService } from 'src/services/api/worthy.service.ts';
import _ from 'lodash';
import { SchedulingType, WorkflowStatus } from 'src/constants/item.constants.tsx';

export interface ItemsState {
    items: Item[] | [];
    loading: boolean;
    error: string | null;
    loadingItems: string[];
    isAllShippingPending: boolean;
}

interface TempItem {
    publicIds: string[];
    shipType: SchedulingType;
    address: string;
    dropOffLocations: string[];
}

interface SchedulePickupSlice extends SchedulePickupParams {
    itemIds: string[];
}

interface ScheduleDropOffSlice {
    itemPublicId: string;
    dropOffLocations: DropOffLocations;
    itemIds: string[]
}

const initialState: ItemsState = {
    items: [],
    loading: false,
    error: null,
    loadingItems: [],
    isAllShippingPending: false,
};

export const itemsSlice = createAppSlice({
    name: 'items',
    initialState,
    reducers: (create) => ({
        updateItem: create.reducer((state, action: PayloadAction<Item>) => {
            let newItem = true;

            state.items = state.items.filter((item) => !item.publicId.includes('temp'));

            state.items = state.items.map((item) => {
                if (item && item.publicId === action.payload.publicId) {
                    newItem = false;

                    return _.mergeWith({}, item, action.payload, (objValue, srcValue) => {
                        if (Array.isArray(objValue)) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                            return srcValue;
                        }
                    });

                } else {
                    return item;
                }
            });

            if (newItem) {
                state.items.push(action.payload);
            }
        }),

        fetchItems: create.asyncThunk<Item[] | []>(
            async () => {
                return backendService.fetchUserItems();
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                fulfilled: (state, action: PayloadAction<Item[] | []>) => {
                    state.items = action.payload;
                    state.loading = false;
                },
                rejected: (state) => {
                    state.loading = false;
                },
            },
        ),
        updateItemPhotos: create.asyncThunk<
            string[],
            { filesToAdd: File[], filesToRemove: string[], itemPublicId: string }
        >(
            async ({ filesToAdd, filesToRemove, itemPublicId }) => {
                if (filesToRemove.length > 0) {
                    await worthyService.removeImages(filesToRemove, itemPublicId);
                }

                if (filesToAdd.length > 0) {
                    const { paths } = await worthyService.uploadImages(filesToAdd, itemPublicId);
                    return paths;
                }
                return [];
            },
            {
                pending: (state, action) => {
                    const item = state.items.find((item) => item.publicId === action.meta.arg.itemPublicId);

                    if (item) {
                        item.photos = item.photos
                                          .filter((photo) => !action.meta.arg.filesToRemove.includes(photo.url))
                                          .concat(action.meta.arg.filesToAdd.map((file, idx) => ({
                                              url: URL.createObjectURL(file),
                                              documentId: -(idx + 1),
                                          })));

                        if (item.photos.length === 0 && action.meta.arg.filesToAdd.length === 0) {
                            item.photos = [];
                        }
                    }

                    state.loadingItems.push(action.meta.arg.itemPublicId);
                },
                fulfilled: (state, action) => {
                    const item = state.items.find((item) => item.publicId === action.meta.arg.itemPublicId);

                    if (item) {
                        item.photos = item.photos.filter(photo => photo.documentId > 0);
                        if (action.payload.length > 0) {
                            item.photos = [
                                ...item.photos,
                                ...action.payload.map((url, idx) => ({
                                    url,
                                    documentId: idx + 1,
                                }))
                            ];
                        }

                        if (item.photos.length === 0 && action.meta.arg.filesToRemove.length > 0 && action.meta.arg.filesToAdd.length === 0) {
                            item.photos = [];
                        }
                    }

                    state.loadingItems = state.loadingItems.filter(id => id !== action.meta.arg.itemPublicId);
                },
                rejected: (state, action) => {
                    const item = state.items.find((item) => item.publicId === action.meta.arg.itemPublicId);

                    if (item) {
                        item.photos = [
                            ...item.photos,
                            ...action.meta.arg.filesToRemove.map((url, idx) => ({
                                url,
                                documentId: idx + 1,
                            }))
                        ];

                        item.photos = item.photos.filter(photo => photo.documentId > 0);
                    }

                    state.loadingItems = state.loadingItems.filter(id => id !== action.meta.arg.itemPublicId);
                },
            }
        ),
        updateCertificate: create.asyncThunk<
            void,
            { itemPublicId: string, certificate: string }
        >(
            async ({ itemPublicId, certificate }) => {
                await worthyService.updateCertificate(itemPublicId, certificate);
            },
            {
                pending: (state, action) => {
                    const item = state.items.find((item) => item.publicId === action.meta.arg.itemPublicId);

                    if (item) {
                        item.itemDetails.certificateId = action.meta.arg.certificate;
                    }

                    state.loadingItems.push(action.meta.arg.itemPublicId);
                },
                fulfilled: (state, action) => {
                    const item = state.items.find((item) => item.publicId === action.meta.arg.itemPublicId);

                    if (item) {
                        item.itemDetails.certificateId = action.meta.arg.certificate;
                    }

                    state.loadingItems = state.loadingItems.filter(id => id !== action.meta.arg.itemPublicId);
                },
                rejected: (state, action) => {
                    const item = state.items.find((item) => item.publicId === action.meta.arg.itemPublicId);

                    if (item) {
                        item.itemDetails.certificateId = '';
                    }

                    state.loadingItems = state.loadingItems.filter(id => id !== action.meta.arg.itemPublicId);
                },
            }
        ),
        createTempInValidItem: create.reducer((state, action: PayloadAction<{ itemType: string }>) => {
            const tempItem: Item = {
                bundleId: 0,
                createdAt: '',
                itemDescription: '',
                itemDetails: {
                    certificateId: '',
                    certificateType: '',
                },
                photos: [],
                appointmentDetails: null,
                schedulingDetails: {
                    type: SchedulingType.PICKUP,
                    localStartTimeString: '',
                    localEndTimeString: '',
                    address: ''
                },
                userId: 0,
                valueCategory: '',
                publicId: `temp-${ Date.now() }`,
                itemType: action.payload.itemType,
                status: WorkflowStatus.PENDING
            };

            state.items = [...state.items, tempItem];
        }),
        removeTempInValidItem: create.reducer((state) => {
            state.items = state.items.filter((item) => !item.publicId.includes('temp'));
        }),
        // todo remove if all fine
        createTempShippingItem: create.reducer((state, action: PayloadAction<TempItem>) => {
            const items = _.filter(state.items, (item) => action.payload.publicIds.includes(item.publicId));
            _.forEach(items, (item) => {
                if (!item.schedulingDetails?.type) {
                    item.status = WorkflowStatus.SHIPPING_SCHEDULED;
                    // @ts-ignore
                    item.schedulingDetails = {
                        type: action.payload.shipType || SchedulingType.DROPOFF,
                        address: action.payload.address,
                        nearestFedExLocations: action.payload.dropOffLocations,
                        shipment: {
                            shipmentId: null,
                            shippingLabelUrl: ''
                        }
                    };
                }
            });
        }),
        addSchedulingDetails: create.reducer((state, action: PayloadAction<{
            publicId: string,
            schedulingDetails: SchedulingDetails
        }>) => {
            const item = state.items.find((item) => item.publicId === action.payload.publicId);

            if (item) {
                item.schedulingDetails = action.payload.schedulingDetails;
            }

        }),
        schedulePickup: create.asyncThunk<
            SchedulingDetails,
            SchedulePickupSlice
        >(async ({ itemPublicId, readyTimestamp, companyCloseTime, dropOffLocations, itemIds }) => {
                const data = {
                    ready_timestamp: readyTimestamp,
                    company_close_time: companyCloseTime,
                    drop_off_locations: dropOffLocations,
                };

                return backendService.scheduleShipping(itemPublicId, SchedulingType.PICKUP, itemIds, data);
            },
            {
                fulfilled: (state, action) => {
                    const response = action.payload;

                    const changedItems = _.filter(state.items, (item) => {
                        return action.meta.arg.itemIds.includes(item.publicId);
                    });

                    _.forEach(changedItems, (item) => {
                        item.status = WorkflowStatus.SHIPPING_SCHEDULED
                        item.schedulingDetails = response;
                    });
                },
                rejected: () => {
                    throw new Error('Error scheduling pickup');
                },
            }),
        scheduleDropOff: create.asyncThunk<
            SchedulingDetails,
            ScheduleDropOffSlice
        >(async ({ itemPublicId, dropOffLocations, itemIds }) => {
                const data = {
                    itemPublicId,
                    drop_off_locations: dropOffLocations,
                };

                return backendService.scheduleShipping(itemPublicId, SchedulingType.DROPOFF, itemIds, data);
            },
            {
                fulfilled: (state, action) => {
                    const response = action.payload;

                    const changedItems = _.filter(state.items, (item) => {
                        return action.meta.arg.itemIds.includes(item.publicId);
                    });

                    _.forEach(changedItems, (item) => {
                        item.status = WorkflowStatus.SHIPPING_SCHEDULED
                        item.schedulingDetails = response;
                    });
                },
                rejected: () => {
                    throw new Error('Error scheduling drop off');
                },
            }),
        setIsAllShippingPending: create.reducer((state, action: PayloadAction<boolean>) => {
            state.isAllShippingPending = action.payload;
        }),
    }),

    selectors: {
        selectLoading: (state) => state.loading,
        isItemLoading: (state, publicId: string) => state.loadingItems.includes(publicId),
        selectError: (state) => state.error,
        getItems: (state) => state.items,
        getIsAllShippingPending: (state) => state.isAllShippingPending,
        getItemPhotos: (state, publicId: string) => {
            if (!publicId) {
                return [];
            }
            const item = state.items.find((item) => item.publicId === publicId);
            return item ? item.photos : [];
        },
        getItemAppointment: (state, publicId?: string) => {
            if (!publicId) {
                return null;
            }
            const item = state.items.find((item) => item.publicId === publicId);
            return item ? item.appointmentDetails : null;
        },
    },
});

export const {
    selectLoading,
    selectError,
    getItems,
    getItemPhotos,
    getItemAppointment,
    isItemLoading,
    getIsAllShippingPending,
} = itemsSlice.selectors;
export const {
    setIsAllShippingPending,
    addSchedulingDetails,
    fetchItems,
    updateItem,
    updateItemPhotos,
    updateCertificate,
    createTempInValidItem,
    removeTempInValidItem,
    createTempShippingItem,
    schedulePickup,
    scheduleDropOff,
} = itemsSlice.actions;
